<template lang="pug">
.container
  page-title
  .card
    .card-content
      app-form(@submit.prevent='submit' :is-saving='isLoading' button-text='Generar')
        field(label='Equipos' field='device')
          devices-picker(v-model='params.devicesId' multiple only-ids required)
        field(label='Zona' field='zone')
          app-select(id='distributor' :items='zones' v-model.number='params.zoneId' required)
        field(label='Fecha inicial' fied='startDate')
          app-date(id='startDate' v-model='params.startDate' type='datetime-local' required)
        field(label='Fecha final' fied='endDate')
          app-date(id='endDate' v-model='params.endDate' type='datetime-local' required)
      div(v-if='rows.length')
        b-button(@click='back') Atrás
        export-button.ml-2(:data='rows' :columns='exportColumns' name='InOutZones')
      hr
      b-table(
        :data='rows'
        :loading='isLoading'
        narrowed
        hoverable
        mobile-cards
        striped
        searchable
      )
        b-table-column(field='name' label='Nombre' sortable searchable v-slot='props') {{ props.row.name }}
        b-table-column(field='inPolyMessage' label='Evento' sortable searchable v-slot='props') {{ props.row.inPolyMessage }}
        b-table-column(field='reportDate' label='Fecha del reporte' sortable searchable v-slot='props') {{ props.row.reportDate | datetime }}
        b-table-column(field='lat' label='Coordenadas' searchable v-slot='props')
          a(:href='`http://maps.google.com/?q=${props.row.lat},${props.row.lng}`' target='_blank') {{ props.row.lat }}, {{ props.row.lng }}
        template(slot='empty')
          .no-data-found
</template>

<script>
import DevicesPicker from '@/components/devices/DevicesPicker'
import zonesService from '@/services/zones.service'
import reportsService from '@/services/reports.service'

const exportColumns = [
  { headerName: 'Nombre', field: 'name' },
  { headerName: 'Evento', field: 'inPolyMessage' },
  { headerName: 'Fecha el reporte', field: 'reportDate' },
  { headerName: 'Latitud', field: 'lat' },
  { headerName: 'Longitud', field: 'lng' }
]

export default {
  components: { DevicesPicker },
  data () {
    return {
      isLoading: false,
      params: {
        ...reportsService.getStartEndDateTimes(),
        devicesId: []
      },
      zones: [],
      rows: [],
      exportColumns
    }
  },
  created () {
    this.loadZones()
  },
  methods: {
    back () {
      this.rows = []
    },
    async loadZones () {
      const result = await zonesService.getAll()
      if (result) {
        this.zones = result
      }
    },
    async submit () {
      this.isLoading = true
      const result = await reportsService.inOutZones(this.params)
      if (result) {
        result.forEach(item => {
          item.inPolyMessage = item.inPoly ? 'Dentro de la zona' : 'Fuera de la zona'
        })
        this.rows = result
      }
      this.isLoading = false
    }
  }
}
</script>
